import React from "react"

import TrainCarIcon from "mdi-react/TrainCarIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import jacksonvilleUrban from "../../images/components/jacksonville-urban.jpg"
import jacksonvilleUrbanBody1 from "../../images/components/jacksonville-urban-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./jacksonville-urban-league-partnership-and-associated-programs.scss"

var JacksonvilleUrbanLeaguePartnershipAndAssociatedPrograms = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="jacksonville-urban-league-partnership-and-associated-programs-page">
      <Banner
        src={jacksonvilleUrban}
        title="Jacksonville Urban League Partnership and Associated Programs"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Jacksonville Urban League Partnership and Associated Programs
        </BorderTitle>
        <div className="body">
          <img className="left" src={jacksonvilleUrbanBody1} alt="" /><p>Development of an online training and placement application system for individuals in Health Zone 1 and others who wish to participate in the Jacksonville Urban League’s job training and placement programs.</p><h4>Community Health Worker Certification</h4> <ol> <li>• A partnership with the Florida Community Health Worker Coalition, Inc. to create a step-by-step community health worker certification guide and assist Health-Smart health empowerment coaches and/or other adults in Health Zone 1 with completing the State of Florida community health worker certification process. </li> </ol><h4>Job Training, Certification, and Placement </h4> <ol> <li>• A partnership with businesses that express interest in helping to find jobs for Health Zone 1 adults in the Jacksonville Urban League’s job training and placement program.</li> <li>• A partnership with other entities to train adults in Health Zone 1 to obtain certifications that prepare them for skilled jobs that are available in Jacksonville.</li> </ol>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/transportation-for-seniors-alliance"
            className="first"
            icon={TrainCarIcon}
            title="Transportation for Seniors Alliance"
            />
          
        </div>
      </PageBody>
    </div>
  </>
  )

export default JacksonvilleUrbanLeaguePartnershipAndAssociatedPrograms
